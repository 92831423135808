<template>
  <section class="section-space how-it-work-section">
      <div class="container">
          <!-- section heading -->
<!--          <SectionHeading classname="text-center" :text="howItWorkData.title" :content="howItWorkData.content" :isMargin="gutterBottom"></SectionHeading>-->
          <SectionHeading classname="text-center" :text="howItWorkData.title" :isMargin="gutterBottom"></SectionHeading>
          <div class="row g-gs justify-content-center">
              <div class="col-10 col-sm-6 col-md-6" v-for="item in howItWorkData.howItWorkList" :key="item.id" :class="classname">
                  <div class="card-htw text-center" :class="classnameTwo">
<!--                      <span :class="item.icon"></span>-->
                      <img :src="item.icon" alt="" style="width: 3rem;margin-bottom: 0.875rem">
                      <h4 class="mb-2">{{ item.title }}</h4>
                      <p class="card-text-s1" v-html="item.content"></p>
                  </div>
              </div><!-- end col -->
          </div><!-- end row -->
      </div><!-- end container -->
  </section><!-- end how-it-work-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'HowItWork',
  props: ['classname', 'title', 'classnameTwo', 'subtitle', 'gutterBottom'],
  data () {
    return {
      SectionData,
      howItWorkData: {
        title: 'Why Choose Us?',
        content: 'Why us?',
        // titleTwo: 'Mint, Buy and Sell or Just Launch <br> Your Own NFT Store',
        // titleThree: 'Get Started in 3 simple steps',
        btnText: 'Create Store',
        howItWorkList: [
          {
            id: 1,
            // icon: 'icon ni ni-wallet icon-lg icon-circle shadow-sm icon-wbg mx-auto mb-4 text-primary',
            icon: require('@/images/tmp/home_graphic-card.png'),
            title: 'New state-of-the-art hardware',
            content: 'We use the newest ASIC and GPU mining gear from Bitmain, Canaan and Nvidia and advanced technologies in our centers.'
          },
          {
            id: 2,
            // icon: require('@/images/home-icon_5.png'),
            icon: require('@/images/tmp/home_financial-profit.png'),
            // icon: 'icon ni ni-file-text icon-lg icon-circle shadow-sm icon-wbg mx-auto mb-4 text-danger',
            title: 'Stable profits',
            content: 'We offer steady fixed income that is automatically deposited into your account every day.'
          },
          {
            id: 3,
            icon: require('@/images/tmp/home_quick-response.png'),
            // icon: 'icon ni ni-camera icon-lg icon-circle shadow-sm icon-wbg mx-auto mb-4 text-info',
            title: 'Fast Regular Payouts',
            content: 'All withdrawal requests will be automatically processed by the system within five minutes.'
          },
          {
            id: 4,
            icon: require('@/images/tmp/home_insurance.png'),
            // icon: 'icon ni ni-money icon-lg icon-circle shadow-sm icon-wbg mx-auto mb-4 text-success',
            title: '100% Money Security',
            content: 'Most funds are safely stored in offline, cold wallets. Enhanced security protocols like McAfee® SECURE and Cloudflare® SECURE are also in place to ensure maximum protection.'
          },
          {
            id: 5,
            icon: require('@/images/tmp/home_rating.png'),
            // icon: 'icon ni ni-money icon-lg icon-circle shadow-sm icon-wbg mx-auto mb-4 text-success',
            title: 'Expert team',
            content: 'Our platform offers cloud mining investment support for everyone, even if you\'re a beginner, you can quickly get started and benefit from it.'
          },
          {
            id: 6,
            icon: require('@/images/tmp/home_planet-earth.png'),
            // icon: 'icon ni ni-money icon-lg icon-circle shadow-sm icon-wbg mx-auto mb-4 text-success',
            title: 'Eco-friendly mining',
            content: 'Our miners and cooling systems are powered by the best monocrystalline solar panels thus environment-friendly and very profitable.'
          }
        ],
        tabNav: [
          {
            id: 1,
            isActive: 'active',
            title: 'Connect Your Wallet',
            slug: 'pills-connect-wallet-tab',
            bsTarget: '#pills-connect-wallet'
          },
          {
            id: 2,
            title: 'Create Your NFT Store',
            slug: 'pills-create-nft-store-tab',
            bsTarget: '#pills-create-nft-store'
          },
          {
            id: 3,
            title: 'Start Selling & Growing',
            slug: 'pills-start-selling-tab',
            bsTarget: '#pills-start-selling'
          },
        ],
      },
    }
  }
}
</script>
